@import '../node_modules/toastr/build/toastr.min.css';

$borderColor:#DFE2E5;

html, body {
  min-height: 100%;
  margin: 0;
  font-size: small;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.k-scheduler {
  height: auto !important;
  overflow: 'auto' !important;
}

.k-scheduler-footer {
  display: none !important;
}

/*Reports CSS*/
.oee-report-header{
  width: 100%;
  margin-top: 20px;
  padding: 0 10px;
  border: 1px solid $borderColor;
  background-color: white;
  min-height:26vh;
/*  height:18vh;*/
}

.oee-report-row{
  min-height:8vh;
  margin-top: 6px;
  padding: 0 7px;
}

.shift-history-header {
  width: 100%;
  margin-top: 20px;
  padding: 10px 10px 20px 10px;
  border: 1px solid $borderColor;
  background-color: white;
}

.shift-summary-title {
  font-size: 16px;
  padding: 15px 0;
  text-align: center;
}
.shift-summary-row {
  padding: 15px 10px;
}

.line-summary-row {
  padding: 15px 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.line-summary-stat {
  text-align: center;
}

.right-align {
  text-align: center;
}

@media (min-width: 770px) {
  .margin-20 {
    margin: 20px 0;
  }

  .summary-border-right {
    border-right: 1px solid black;
  }
}

.dashboard-line {

  .chart-wrapper div.k-chart {
    height: 250px;
    /*
    height: 100%;
    */
    border: 1px solid black;
  }
  .after-marker {
    color: transparent;
  }
  .bad-marker {
    color: transparent;
  }
  .disconnected-marker {
    color: black;
  }
  .good-marker {
    color: #3aba23;
  }
  .running-marker {
    color: #3aba23;
  }
  .maintenance-marker {
    color: #ffff00;
  }
  .break-marker {
    color: #ffff00;
  }
  .stopped-marker {
    color: red;
  }

}

.k-tooltip {
  background: orange !important;
  color: black;
}

.k-numerictextbox {
  border-bottom-color: transparent !important;
  .k-numeric-wrap {
    border-bottom-color: transparent !important;
  }
  .k-input {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-left: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 10px;
    min-height: 45px;
    font-size: 14px;
    color: rgb(74, 74, 74);
  }
  .k-select {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-right: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 45px;
    width: 30px !important;
    .k-link-increase .k-icon {
      bottom: 0px
    }
    .k-link-decrease .k-icon {
      top: 0px
    }
  }
}

.numerictextbox-large {
  .k-numerictextbox {
    border-bottom-color: transparent !important;
    .k-numeric-wrap {
      border-bottom-color: transparent !important;
    }
    .k-input {
      border-top: 1px solid rgba(0,0,0,0.23) !important;
      border-left: 1px solid rgba(0,0,0,0.23) !important;
      border-bottom: 1px solid rgba(0,0,0,0.23) !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 10px;
      min-height: 55px;
      font-size: 14px;
      color: rgb(74, 74, 74);
    }
    .k-select {
      border-top: 1px solid rgba(0,0,0,0.23) !important;
      border-right: 1px solid rgba(0,0,0,0.23) !important;
      border-bottom: 1px solid rgba(0,0,0,0.23) !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-height: 55px;
      width: 30px !important;
      .k-link-increase .k-icon {
        bottom: 0px
      }
      .k-link-decrease .k-icon {
        top: 0px
      }
    }
  }
}

.numerictextbox-large-warning {
  .k-numerictextbox {
    border-bottom-color: transparent !important;
    .k-numeric-wrap {
      border-bottom-color: transparent !important;
    }
    .k-input {
      border-top: 1px solid red !important;
      border-left: 1px solid red !important;
      border-bottom: 1px solid red !important;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 10px;
      min-height: 55px;
      font-size: 14px;
      color: rgb(74, 74, 74);
    }
    .k-select {
      border-top: 1px solid red !important;
      border-right: 1px solid red !important;
      border-bottom: 1px solid red !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      min-height: 55px;
      width: 30px !important;
      .k-link-increase .k-icon {
        bottom: 0px
      }
      .k-link-decrease .k-icon {
        top: 0px
      }
    }
  }
}

.k-button-primary, .k-time-accept, .k-button .k-primary {
  background-color: #577790 !important;
  &:hover {
    background-color: #809cb3 !important;
  }
}

.k-time-now {
  color: #577790 !important;
  &:hover {
    color: #809cb3 !important;
  }
}

.k-timepicker {
  border-bottom-color: transparent !important;
  .k-picker-wrap {
    border-bottom-color: transparent !important;
  }
  .k-input {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-left: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 5px;
    min-height: 52px;
    font-size: 14px;
    color: rgb(74, 74, 74);
  }
  .k-select {
    border-top: 1px solid rgba(0,0,0,0.23) !important;
    border-right: 1px solid rgba(0,0,0,0.23) !important;
    border-bottom: 1px solid rgba(0,0,0,0.23) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 45px;
    width: 30px !important;
    .k-link-increase .k-icon {
      bottom: 0px
    }
    .k-link-decrease .k-icon {
      top: 0px
    }
  }
}

.k-current-time {
  display: none
}
